import React from "react";
import { useState } from 'react'
import emailjs from 'emailjs-com'

export default function Contact({ bgColor }) {
  const initialState = {
    name: '',
    email: '',
    message: '',
  }
  const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })
  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        'service_wc2y2wc', 'template_qp7aljs', e.target, '3V-5RX_P2Myubnlzi'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
        },
        (error) => {
          console.log(error.text)
        }
      )
  }

  return (
    <>
      <section
        id="contact"
        className={`contact-us ptb-100 ${bgColor ? "gray-light-bg" : ""}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 pb-3 message-box d-none">
              <div className="alert alert-danger"></div>
            </div>
            <div className="col-md-5">
              <div className="section-heading">
                <h2>Contact Us</h2>
   
              </div>
              <div className="footer-address">

                <ul>
               
                  <li>
                    <span>
                      Email:&nbsp;  
                      <a style={{color:'#4dd1d1'}} href="mailto:swag@coo.twi.gr">
                      swag@coo.twi.gr
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-7">
              <form
                name='sentMessage' validate onSubmit={handleSubmit}
                id="contactForm"
                className="contact-us-form"
              >

                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                         type='text'
                         id='name'
                         value={name}
                         name='name'
                         className='form-control'
                         required
                         onChange={handleChange}
                        placeholder="Enter name"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="email"
                        id='email'
                        value={email}
                        name='email'
                        className='form-control'
                        required
                        onChange={handleChange}
                        placeholder="Enter email"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
              
                
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        name='message'
                        id='message'
                        className='form-control'
                        value={message}
                        placeholder='Message'
                        required
                        onChange={handleChange}
                        rows="7"
                        cols="25"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 mt-3">
                    <button
                      type="submit"
                      className="btn solid-btn"
                      id="btnContactUs"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
              <p className="form-message"></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
