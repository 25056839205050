import React from "react";
import Layout from "../components/layout/Layout";
import Navbar from "../components/layout/header/Navbar";
import poster from "../img/poster.png"
import leaflet from "../img/leaflet.png"
import MyPDF1 from "./leaflet.pdf"
import MyPDF2 from "./poster.pdf"
import Footer from "../components/layout/footer/Footer";
import HeroSix2 from "../themes/index-6/HeroSix2";


export default function HomeSix() {
  return (
    <Layout>
        <Navbar darkBg/>
        <HeroSix2 title='Outcomes'/>
        <section className="promo-section ptb-100" style={{backgroundColor:'whitesmoke'}}>
       
       <div className="container" style={{width:'80%'}}> 
         <div className="row justify-content-center">
           <div className="col-lg-12 col-md-12" style={{textAlign:'justify'}}>
             <div className="section-heading text-center mb-10">
               <h2  >Promotional Material</h2>
               <div style={{float:'left'}}>
              <img style={{width:'230px',position:'relative',marginRight:'40px'}} src={leaflet}></img><br/>
              <a style={{color:'#4dd1d1',float:'left'}} id='newsletter' href={MyPDF1} download="SWAG Leaflet.pdf">Download SWAG Leaflet</a>
              </div> 
              <div style={{float:'left'}}>
              <img style={{width:'121px',position:'relative',float:'left'}} src={poster}></img><br/>
              <a style={{color:'#4dd1d1',width:'130px'}}id='newsletter' href={MyPDF2} download="SWAG Poster.pdf">Download SWAG Poster</a>
              </div> 
             </div>
           </div>
         </div>
        
       </div>
     </section>
      <section className="promo-section ptb-100" id='outcomes'>
       
       <div className="container">
         <div className="row justify-content-center">
           <div className="col-lg-12 col-md-12" style={{textAlign:'justify'}}>
             <div className="section-heading text-center mb-10">
               <h2 >Publications</h2>
               
             </div>
           </div>
         </div>
        
       </div>
     </section>
     <section className="promo-section ptb-100" style={{backgroundColor:'whitesmoke'}}>
       
       <div className="container">
         <div className="row justify-content-center">
           <div className="col-lg-12 col-md-12" style={{textAlign:'justify'}}>
             <div className="section-heading text-center mb-10">
               <h2  >Deliverables</h2>
               
             </div>
           </div>
         </div>
        
       </div>
     </section>
     
     
      <Footer space />
    </Layout>
  );
}
