import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faLinkedinIn, faXTwitter,faYoutube } from '@fortawesome/free-brands-svg-icons'
import eu from './eu.jpg'
export default function Footer({ space }) {
  return (
    <>
      <footer className="footer-section">
        <div
          className={`footer-top background-img-2 ${space ? "pt-150" : "pt-60"}`}
          style={{
            background:
              "#13566f",
          }}
        >
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
                <div className="footer-nav-wrap text-white">
                  <img
                    src="assets/img/logos/swag_final_white.png"
                    alt="footer logo"
                    width="120"
                    className="img-fluid mb-3"
                  />
             <br/>
                 
                  <p style={{width:'250px',color:'whitesmoke'}}> <img src={eu} style={{width:'40px', position:'relative',top:'6px',margin:'0px 8px 2px 0px', border:'1px solid whitesmoke'}}></img>
                  This project has received funding from the European Union’s Horizon Europe programme under Grant Agreement N° 101120408.

                  </p>

                  <div className="social-list-wrap">
                    <ul className="social-list list-inline list-unstyled">
                      <li className="list-inline-item">
                        <a href="https://www.linkedin.com/showcase/swag-robotics/
" target="_blank" title="Linkedin">
                        <FontAwesomeIcon icon={faLinkedinIn}></FontAwesomeIcon>

                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="https://twitter.com/SWAG_project_EU
" target="_blank" title="Twitter">
                        <FontAwesomeIcon icon={faXTwitter}></FontAwesomeIcon>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="https://www.youtube.com/channel/UCf0cWTRlYR-YGT0bmI9FgPA
" target="_blank" title="Youtube">
                        <FontAwesomeIcon icon={faYoutube}></FontAwesomeIcon>
                        </a>
                      </li>
                     
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-8">
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Resources</h5>
                      <ul className="list-unstyled">
                        <li className="mb-2">
                          <a href="/#events">Events</a>
                        </li>
                        <li className="mb-2">
                          <a href="/news">News</a>
                        </li>
                        <li className="mb-2">
                          <a href="/#oucomes">Outcomes</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Project</h5>
                      <ul className="list-unstyled support-list">
                        <li className="mb-2">
                          <a href="#nutshell">About</a>
                        </li>
                        <li className="mb-2">
                          <a href="/#consortium">Consortium</a>
                        </li>
                        <li className="mb-2">
                          <a href="/#challenges">Challenges</a>
                        </li>
                        <li className="mb-2">
                          <a href="/#solutions">Solutions</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Project Details</h5>
                      <ul className="list-unstyled support-list" style={{fontSize:'14px'}}>
                       <li><span style={{fontSize:'14px'}}>Starting date:</span> 1st November 2023</li>
                       <li><span style={{fontSize:'14px'}}>Duration:</span> 48 months</li>
                       <li><span style={{fontSize:'14px'}}>Coordinator:</span> TWI Hellas</li>
                       <li><span style={{fontSize:'14px'}}>Consortium:</span> 13 partners from 7 European countries</li>
                       <li>More SWAG info at <a href='https://cordis.europa.eu/project/id/101120408' style={{color:'#4dd1d1',fontSize:'14px'}}>CORDIS</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom border-gray-light mt-5 py-3">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-7">
                  <div className="copyright-wrap small-text">
                   
                  </div>
                </div>
                <div className="col-md-6 col-lg-5">
                  <div className="terms-policy-wrap text-lg-right text-md-right text-left">
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
