import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";
import img from "./iStock-966248982.jpg"
import Fade from 'react-reveal/Fade';



export default function HeroSix() {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <section
        className="hero-section background-img pt-100"
        style={{
          
          backgroundImage:
            "url('assets/img/shutterstock2.jpg')no-repeat center center / cover)",
        }}
      >
        

        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6 col-lg-6">
              <div className="hero-content-left position-relative mt-lg-0 mt-md-0 mt-5 text-white">
              <br/> <br/> <br/>
                <h1 className="text-white">
                Soft Wearable Assistive Garments for Human Empowerment
                </h1>
                <br/>
                <Fade bottom delay={500}>
                <h3 className="lead" style={{color:'whitesmoke', fontSize:'24px',fontStyle:'italic'}}>
                Exploring a fundamentally new approach<br></br>to engineering soft structures
                </h3>
                </Fade>

               
              </div>
            </div>
            
          </div>
     
        </div>
        <div className="section-shape position-absolute">
          <img src="assets/img/wave-shap.svg" alt="shape" />
        </div>
      </section>
    </>
  );
}
