import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faLinkedinIn, faXTwitter,faYoutube } from '@fortawesome/free-brands-svg-icons'

const Navbar = ({ darkBg, classOption }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const stickyheader = document.querySelector(".header");
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <>
      <header className={`header ${classOption}`}>
      <nav
          className={`navbar navbar-expand-lg fixed-top ${darkBg ? "bg-transparent" : "custom-nav #253a4c-bg"
            } ${scroll > headerTop ? "affix" : ""}`}
        >
          <div className="container">
            
            <Link to="/" className="navbar-brand">
              {scroll > headerTop ?  (
                <img
                  src="assets/img/logos/swag_final.png"
                  width="120"
                  alt="logo"
                  className="img-fluid"
                />
              ) : (
                <img
                  src="assets/img/logos/swag_final_white.png"
                  width="120"
                  alt="logo"
                  className="img-fluid"
                />
              )}
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="ti-menu"></span>
            </button>
            {scroll > headerTop ?  (
            <div
              className="collapse navbar-collapse main-menu"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                  <HashLink className="nav-link" style={{color:'#13566f', opacity:'1'}} smooth to='/#home'>
                    Home
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink className="nav-link" style={{color:'#13566f', opacity:'1'}} smooth to='/partners'>
                    Partners
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink className="nav-link" style={{color:'#13566f', opacity:'1'}} smooth to="/news">
                    News
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink className="nav-link" style={{color:'#13566f', opacity:'1'}} smooth to="/outcomes">
                    Outcomes
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink className="nav-link" style={{color:'#13566f', opacity:'1'}} smooth to="/#contact">
                    Connect
                  </HashLink>
                </li>
              
              </ul>
            </div>) : (
                <div
                className="collapse navbar-collapse main-menu"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                    <HashLink className="nav-link" smooth to='/#home'>
                      Home
                    </HashLink>
                  </li>
                  <li className="nav-item">
                    <HashLink className="nav-link" smooth to='/partners'>
                      Partners
                    </HashLink>
                  </li>
                  <li className="nav-item">
                    <HashLink className="nav-link" smooth to="/news">
                      News
                    </HashLink>
                  </li>
                  <li className="nav-item">
                    <HashLink className="nav-link" smooth to="/outcomes">
                      Outcomes
                    </HashLink>
                  </li>
                  <li className="nav-item">
                    <HashLink className="nav-link" smooth to="/#contact">
                      Connect
                    </HashLink>
                  </li>
                  <li className="list-inline-item">
                        <a href="https://www.linkedin.com/showcase/swag-robotics/
" target="_blank" title="Linkedin">
                        <FontAwesomeIcon icon={faLinkedinIn}></FontAwesomeIcon>

                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="https://twitter.com/SWAG_project_EU
" target="_blank" title="Twitter">
                        <FontAwesomeIcon icon={faXTwitter}></FontAwesomeIcon>
                        </a>
                      </li>
                </ul>
              </div>
              )}
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
