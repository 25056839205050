import React from "react";
import Layout from "../components/layout/Layout";
import Navbar from "../components/layout/header/Navbar";

import Footer from "../components/layout/footer/Footer";
import HeroSix2 from "../themes/index-6/HeroSix2";
import { useNavigate } from 'react-router-dom';
import image1 from "../img/Picture12.png"
import image2 from "../img/Picture333.jpg"

export default function HomeSix() {
  
const navigate = useNavigate();

function handleClick1() {

  navigate('/SWAG Project Launched to Revolutionise Soft Robotic Exosuits');
}
function handleClick3() {

  navigate('/SWAG Partners gather in The Netherlands to discuss latest developments');
}
function handleClick2() {

  navigate('/SWAG debuts at online showcase event');
}
  return (
    <Layout>
        <Navbar darkBg/>
      <HeroSix2 title='News'/>
      <section className="promo-section ptb-100" id='news' style={{backgroundColor:'whitesmoke'}}>
       
       <div className="container">
         <div className="row justify-content-center">
           <div className="col-lg-12 col-md-12" style={{textAlign:'justify'}}>
             <div className="section-heading text-center mb-10">
               <h2 >Articles & Newsletters</h2>
               <br/>
               <div class="container">
  <div class="card-deck my-3">
  <div class="card" style={{cursor:"pointer"}} onClick={handleClick3}>
  <img class="card-img-top" src={image2} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title" style={{fontSize:'17px'}}>SWAG Partners gather in Netherlands to discuss latest developments
</h5>
        <p class="card-text" style={{fontSize:'14px'}}>Representatives from the SWAG project gathered for two days on 4th-5th April in Enschede, the Netherlands, for their second in-person meeting....</p>
        <p class="card-text" ><small class="text-muted">10/04/2024</small><span className="span2" style={{float:'right'}}> Read more</span></p>
      </div>
    </div>
   
    
  <div class="card" style={{cursor:"pointer"}} onClick={handleClick1}>
      <div class="card-body">
        <h5 class="card-title" style={{fontSize:'17px'}}>SWAG Project Launched to Revolutionise Soft Robotic Exosuits
</h5>
        <p class="card-text" style={{fontSize:'14px'}}>The Horizon Europe funded SWAG project aims to set a new benchmark in motion assistive technologies, developing fully soft human-assistive exoskeletons. ...</p>
        <p class="card-text" ><small class="text-muted">04/04/2024</small><span className="span2" style={{float:'right'}}> Read more</span></p>
      </div>
    </div>
   
    </div> </div>
             </div>
           </div>
         </div>
        
       </div>
     </section>

     <section className="promo-section ptb-100">
       
       <div className="container">
         <div className="row justify-content-center">
           <div className="col-lg-12 col-md-12" style={{textAlign:'justify'}}>
             <div className="section-heading text-center mb-10">
               <h2>Media</h2>
               
             </div>
           </div>
         </div>
        
       </div>
     </section>
     <section className="promo-section ptb-100" style={{backgroundColor:'whitesmoke'}} id='events'>
       
       <div className="container">
         <div className="row justify-content-center">
           <div className="col-lg-12 col-md-12" style={{textAlign:'justify'}}>
             <div className="section-heading text-center mb-10">
               <h2>Events</h2>
               
               <div class="container">
  <div class="card-deck my-3">

    <div class="card" style={{cursor:"pointer"}} onClick={handleClick2}>
      <img class="card-img-top" src={image1} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">SWAG debuts at online showcase event</h5>
        <p class="card-text">On February 22nd SWAG had its public debut at an online event co-organised by Adra - AI-Data-Robotics-Association, AI-on-Demand Platform and the European Commission.</p>
        <p class="card-text"><small class="text-muted">07/04/2024</small> <span className="span2" style={{float:'right'}}>Read more</span></p>
        
      </div>
    </div>
    </div> </div>

             </div>
           </div>
         </div>
        
       </div>
     </section>
     
      <Footer space />
    </Layout>
  );
}
