import React from "react";
import spain from "../../img/spain.svg"
import italy from "../../img/italy.svg"
import greece from "../../img/greece.svg"
import netherlands from "../../img/netherlands.svg"
import germany from "../../img/germany.svg"
import uk from "../../img/uk.svg"
import slovenia from "../../img/slovenia.svg"

export default function FeatureImgFour() {
  return (
    <>
      <section style={{backgroundColor:'#f5f5f5'}}
        id="consortium"
        className="imageblock-section switchable switchable-content"
      >
        <div className="imageblock-section-img col-lg-5 col-md-4">
          <div
            className="background-image-holder"
            style={{
              background:
                "url('assets/img/map.svg')no-repeat center center / cover",
              opacity: 1,
            }}
          >
            {" "}
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-7">
              <div className="row align-items-center ptb-100">
                <div className="col-lg-12">
                <h2  style={{textAlign:'left'}}>Consortium</h2>
          
                  <div className="d-flex align-items-start mb-2">
                      <img src={greece} style={{width:'30px', position:'relative', top:'12px', marginRight:'20px'}}/>  
                    <div className="icon-text">
                    <p><b>Greece</b> is represented by TWI Hellas (THL), the project coordinator, the Hellenic Mediterranean University (HMU) and Bendabl (BBL)
                   </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-start mb-2">
                      <img src={germany} style={{width:'30px', position:'relative', top:'0px', marginRight:'20px'}}/>  
                    <div className="icon-text">
                    <p><b>Germany</b> is represented by RUPRECHT-KARLS-UNIVERSITAET HEIDELBERG (UHEI).
                   </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-start mb-2">
                      <img src={italy} style={{width:'30px', position:'relative', top:'12px', marginRight:'20px'}}/>  
                    <div className="icon-text">
                    <p><b>Italy</b> is represented by SCUOLA SUPERIORE DI STUDI UNIVERSITARI E DI PERFEZIONAMENTO S ANNA (SSSE) and IUVO SRL (IUVO).
                   </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-start mb-2">
                      <img src={spain} style={{width:'30px', position:'relative', top:'0px', marginRight:'20px'}}/>  
                    <div className="icon-text">
                    <p><b>Spain</b>  is represented by FUNDACIO EURECAT (EUT).
                   </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-start mb-2">
                      <img src={slovenia} style={{width:'30px', position:'relative', top:'00px', marginRight:'20px'}}/>  
                    <div className="icon-text">
                    <p><b>Slovenia</b> is represented by the INSTITUT JOZEF STEFAN (IJS).
                   </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-start mb-2">
                      <img src={netherlands} style={{width:'30px', position:'relative', top:'12px', marginRight:'20px'}}/>  
                    <div className="icon-text">
                    <p><b>Netherlands</b> is represented by UNIVERSITEIT TWENTE (UOT) and ROESSINGH RESEARCH AND DEVELOPMENT BV (RRD).
                   </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-start mb-2">
                      <img src={uk} style={{width:'30px', position:'relative', top:'18px', marginRight:'20px'}}/>  
                    <div className="icon-text">
                    <p>The <b>UK</b> is represented by THE UNIVERSITY OF HERTFORDSHIRE HIGHER EDUCATION CORPORATION (HE), BRUNEL UNIVERSITY LONDON (BUL) and IMPERIAL COLLEGE OF SCIENCE TECHNOLOGY AND MEDICINE (ICL).
                   </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
