import React from "react";
import stoxos from "../../img/aim.png"


export default function AboutTwo() {
  return (
    <>
      <section id="solutions" className="about-us ptb-100 " style={{backgroundColor:'whitesmoke'}}>
        <div className="container">
        <h2>SWAG solutions</h2>
              <p className="mb-4 lh-190">
              In order to address these challenges and transform soft wearable robotics, SWAG will make advances in fabric-based soft robotics, integrated sensing, user intent tracking, and distributed actuation. Our goals include:
              </p>
          <div className="row align-items-center">
            <div className="col-md-5">
              <div className="about-content-right">
              <img style={{borderRadius:'10px'}}
                  src="assets/img/solutions.png"
                  width="400"
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
            
            <div className="col-lg-7">
            
              <ul className="list-unstyled">
              <li  style={{marginBottom:'10px'}}><img id='lamp' style={{width:'40px',float:'left',marginRight:'10px',position:'relative',top:'4px'}}src={stoxos}/><p style={{marginLeft:'50px'}}>Introducing a new design approach for wearable robots based on adaptability and human-centric design. This involves using optimized inflatable fabrics to cover and support entire body regions, not just individual joints.</p></li>
              <li style={{marginBottom:'10px'}}><img id='lamp' style={{width:'40px',float:'left',marginRight:'10px',position:'relative',top:'4px'}}src={stoxos}/><p style={{marginLeft:'50px'}}>Providing personalized real-time motion assistance using a combination of distributed sensing, control, and user-intent prediction algorithms based on data-driven models. This ensures accurate and adaptive support for various movements.</p></li>
                <li style={{marginBottom:'10px'}}><img id='lamp' style={{width:'40px',float:'left',marginRight:'10px',position:'relative',top:'4px'}}src={stoxos}/><p style={{marginLeft:'50px'}}>Eliminating the need for tethered operation and simultaneously supporting muscles and joints throughout the entire lower limb system.  This is made possible by a single pneumatic source and a closed pneumatic circuit efficiently recycling air.</p></li>
                <li style={{marginBottom:'10px'}}><img id='lamp' style={{width:'40px',float:'left',marginRight:'10px',position:'relative',top:'4px'}}src={stoxos}/><p style={{marginLeft:'50px'}}>Overcoming the limitations of wearable devices by adopting a multidisciplinary approach that combines soft design, embedded sensing, functional apparel, smart textile design, biomechanical modeling, materials science, distributed control; with AI at the centre.</p></li>
              
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
/* 
<section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
        <h2>The challenges</h2>
              <p className="mb-4 lh-190">
              SWAG will revolutionize the field of human motion assistance by eliminating challenges that are currently blocking progress.
              </p>
          <div className="row align-items-center">
            <div className="col-md-5">
              <div className="about-content-right">
                <img style={{borderRadius:'10px'}}
                  src="assets/img/shutterstock1.jpg"
                  width="500"
                  alt="about us"
                  className="img-fluid"
                />
                
                <img style={{marginTop:'10px',borderRadius:'10px'}}
                  src="assets/img/shutterstock3.jpg"
                  width="500"
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
            
            <div className="col-lg-7">
            
              <ul className="list-unstyled">
                <li className="py-1">
              
                  <div className="d-flex align-items-center">
                  <div>
                      <div style={{width:'44px'}}>
                      <img style={{width:'34px'}} src={stoxos}></img>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0"><b style={{color:'#14566f'}}>User comfort and efficiency</b></p>
                     
                    </div>
                  </div>
                
                  <p>Existing wearable robots use heavy and bulky devices to assist isolated joints. When activating specific joints they often ignore the overall impact on the body's biomechanics. Current soft wearable solutions struggle to conform to an individual’s physiology, and to adjust to each user's unique motion needs during various daily activities.</p>
                </li>
                <li className="py-1">
                  <div className="d-flex align-items-center">
                  <div style={{width:'44px'}}>
                      <img src={stoxos} style={{width:'34px'}}></img>
                      </div>
                    <div>
                    <p className="mb-0"><b style={{color:'#14566f'}}>Pneumatic actuation
</b></p>
                     
                     </div>
                   </div>
                   
                   <p>Pneumatically-powered exoskeletons struggle with power supply, as suitable portable pneumatic sources are not available, and only traditional air compressors have been used in past solutions, which are not practical, for various reasons.</p>
                 </li>
                <li className="py-1">
                  <div className="d-flex align-items-center">
                  <div style={{width:'44px'}}>
                      <img style={{width:'34px'}} src={stoxos}></img>
                      </div>
                    <div>
                    <p className="mb-0"><b style={{color:'#14566f'}}>Data acquisition and processing</b></p>
                     
                     </div>
                   </div>
                   
                   <p>Soft sensors have been developed with the aim to address the needs of soft systems, tracking pressure, strain, force and contact interactions. Soft sensing technologies and computational algorithms have advanced in collecting data from various sources in controlled settings and specific activities. However, these soft approaches face challenges in consistently functioning in real-world situations and securely responding to unexpected inputs and interactions. </p>
                 </li>
              
              </ul>
            </div>
          </div>
        </div>
      </section>
*/