import React from "react";

import Footer from "../layout/footer/Footer";
import Navbar from "../layout/header/Navbar";
import Layout from "../layout/Layout";
import PageHeader from "../team/PageHeader";
import HeroSix2 from "../../themes/index-6/HeroSix2";
import image1 from "../../img/Picture111.jpg"
import image2 from "../../img/Picture222.jpg"
import image3 from "../../img/Picture333.jpg"



export default function Article1() {

  
  return (
    <Layout>
            <Navbar darkBg/>
      <HeroSix2 />
    
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#e95d04'}}>
                SWAG Partners gather in The Netherlands to discuss latest developments

                          </h3>
                          <br/>
                          <img
                  src={image1}
                  style={{borderRadius:'10px',width:'400px',float:'right',marginLeft:'20px'}}
                  alt="about us"
                  className="img-fluid"
                />
                <p>Representatives from the SWAG project gathered for two days on 4th-5th April in Enschede, the Netherlands, for their second in-person meeting. Participants shared updates on various SWAG components, including musculoskeletal modeling, smart actuator development, pneumatic supply system design, as well as plans for user engagement and the consolidation of the use-case scenarios that the project will target. </p>       
              <p>The main goal was to discuss the latest results, gather valuable feedback from the entire consortium, consisting of 
              <a style={{color:'#f45652'}}href='https://www.twi-hellas.com/'> TWI Hellas</a>, 
                <a style={{color:'#f45652'}}href='https://csrl.hmu.gr/'> Hellenic Mediterranean University (HMU)</a>, <a style={{color:'#f45652'}}href='https://bendabl.com/'>Bendabl</a>, 
                <a style={{color:'#f45652'}}href='https://www.uni-heidelberg.de/'> Heidelberg University</a>, <a style={{color:'#f45652'}}href='https://www.santannapisa.it/en/institute/biorobotics'>Scuola Superiore Sant'Anna</a>, 
                <a style={{color:'#f45652'}}href='https://www.iuvo.company/'> IUVO</a>, <a style={{color:'#f45652'}}href='https://swag-project.eu/www.eurecat.org'>Eurecat</a>, 
                <a style={{color:'#f45652'}}href='https://nbr.ijs.si/'> Jožef Stefan Institute</a>, <a style={{color:'#f45652'}}href='https://www.utwente.nl/en/et/be/research/neuro-mech-eng-group/'>University of Twente (UT)</a>, 
                <a style={{color:'#f45652'}}href='https://www.rrd.nl/en/'> Roessingh Research and Development (RRD)</a>, 
                <a style={{color:'#f45652'}}href='https://www.herts.ac.uk/kaspar/research-and-development'> University of Hertfordshire</a>, <a style={{color:'#f45652'}}href='https://www.brunel.ac.uk/research/Centres/Brunel-Innovation-Centre/About-us'>Brunel Innovation Centre (BIC)</a>,
                 <a style={{color:'#f45652'}}href='https://www.imperial.ac.uk/bioengineering/research/'> IMPERIAL COLLEGE OF SCIENCE TECHNOLOGY & MEDICINE </a>
               and reach agreements on the next steps. 
</p>
<img
                  src={image2}
                  style={{borderRadius:'10px',width:'300px',float:'left',marginRight:'20px'}}
                  alt="about us"
                  className="img-fluid"
                />
 <p>Dedicated workshops were held to progress the development of the SWAG exosuit’s architecture, including discussions about the envisioned exosuit’s functional requirements, and considerations about the various components’ design specifications. By the end of the meeting, the partners left having confirmed important design decisions, concluding the first architectural design iteration, which will be reported in upcoming deliverables.</p>
 <img
                  src={image3}
                  style={{borderRadius:'10px',width:'400px',float:'right',marginLeft:'20px'}}
                  alt="about us"
                  className="img-fluid"
                />
 <p>Partners also had the opportunity to visit the labs of hosts Roessingh Research and Development and University of Twente, discovering their latest technology and research. The hosts introduced the consortium to the state-of-the-art sensors and controllers to be integrated in the SWAG exosuit, as well as to the facilities and motion capture setup that will be used to validate SWAG at the end of the project.</p>       
 </div>      </div>
           
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
