import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";
import HeroSix2 from "../../themes/index-6/HeroSix2";




export default function Article1() {

  
  return (
    <Layout>
            <Navbar darkBg/>
      <HeroSix2 />
    
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#e95d04'}}>
                SWAG Project Launched to Revolutionise Soft Robotic Exosuits
                          </h3>
         
                <p> The <a style={{color:'#f45652'}}href='https://research-and-innovation.ec.europa.eu/funding/funding-opportunities/funding-programmes-and-open-calls/horizon-europe_en'>Horizon Europe</a> funded SWAG project aims to set a new benchmark in motion assistive technologies, 
                    developing fully soft human-assistive exoskeletons. 
</p>       
                <p>SWAG, standing for Soft Wearable Assistive Garments, will explore a fundamentally new approach to engineering soft wearable robots, that completely omit rigid materials in favour of soft, inflatable ones. SWAG’s innovative approach aims to create exoskeletal devices carefully designed to target strain-prone parts of the human body, specifically the lower body and core. 
                    </p>
                   <p>Soft lower extremity exosuits are one of the most challenging research topics, and this requires a multidisciplinary approach, involving diverse fields such as biomechanics, robotics, control, AI and ergonomics.  </p>
              <p>The SWAG soft structures aim to seamlessly adapt to the intricate motions of each joint, using high-strength fabrics and films, offering a level of flexibility and adaptability unprecedented in existing solutions. The SWAG solution aims to support flexible and adaptable movements that humans naturally perform, inspired by continuum robots, a type of robotic system that draws from  structures found in nature. This will provide comprehensive support for joints such as the ankle or hip</p>
              <p>The advancements of SWAG will be demonstrated in four scenarios, showcasing its transformative potential in real-world settings, including assistance for daily living and strength-building; but the potential applications are many.</p>
              <p>Spearheaded by <a style={{color:'#f45652'}}href='https://www.twi-hellas.com/'>TWI Hellas</a>, based in Athens, Greece, the project consortium brings together 13 partners from six EU countries and the UK 
                (<a style={{color:'#f45652'}}href='https://csrl.hmu.gr/'>Hellenic Mediterranean University (HMU)</a>, <a style={{color:'#f45652'}}href='https://bendabl.com/'>Bendabl</a>, 
                <a style={{color:'#f45652'}}href='https://www.uni-heidelberg.de/'> Heidelberg University</a>, <a style={{color:'#f45652'}}href='https://www.santannapisa.it/en/institute/biorobotics'>Scuola Superiore Sant'Anna</a>, 
                <a style={{color:'#f45652'}}href='https://www.iuvo.company/'> IUVO</a>, <a style={{color:'#f45652'}}href='https://swag-project.eu/www.eurecat.org'>Eurecat</a>, 
                <a style={{color:'#f45652'}}href='https://nbr.ijs.si/'> Jožef Stefan Institute</a>, <a style={{color:'#f45652'}}href='https://www.utwente.nl/en/et/be/research/neuro-mech-eng-group/'>University of Twente (UT)</a>, 
                <a style={{color:'#f45652'}}href='https://www.rrd.nl/en/'> Roessingh Research and Development (RRD)</a>, 
                and the UK (<a style={{color:'#f45652'}}href='https://www.herts.ac.uk/kaspar/research-and-development'>University of Hertfordshire</a>, <a style={{color:'#f45652'}}href='https://www.brunel.ac.uk/research/Centres/Brunel-Innovation-Centre/About-us'>Brunel Innovation Centre (BIC)</a>,
                 <a style={{color:'#f45652'}}href='https://www.imperial.ac.uk/bioengineering/research/'> Imperial College London</a>). </p>
              <p>The group boasts leading academics renowned for their expertise in diverse fields, Research and Technology Organizations with a proven track record in developing and validating application-driven solutions, and two commercial partners committed to spearheading the use and commercialisation of SWAG’s outcomes.</p>
              </div>
            </div>
           
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
