import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";
import img from "./iStock-966248982.jpg"


export default function HeroSix2({title}) {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <section
        className="hero-section background-img pt-100"
        style={{
          height:"300px",
          backgroundImage:
            "url('assets/img/hero-bg-4.jpg')no-repeat center center / cover)",
        }}
      >
        
       
              <div className="hero-content-left position-relative mt-lg-0 mt-md-0 mt-5 text-white">
              <br/> <br/>  <br/>
              <h2 id='headertitle' style={{textAlign:'center', color:'whitesmoke',zIndex:'1'}}>
               {title} </h2>
            

               
              </div>
            
    
     
      </section>
    </>
  );
}
