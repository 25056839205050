import React from "react";

import Footer from "../layout/footer/Footer";
import Navbar from "../layout/header/Navbar";
import Layout from "../layout/Layout";
import PageHeader from "../team/PageHeader";
import HeroSix2 from "../../themes/index-6/HeroSix2";
import image1 from "../../img/Picture12.png"



export default function Article1() {

  
  return (
    <Layout>
            <Navbar darkBg/>
      <HeroSix2 />
    
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#e95d04'}}>
                SWAG debuts at online showcase event

                          </h3>
                          <img
                  src={image1}
                  style={{borderRadius:'10px',width:'400px',float:'right',marginLeft:'20px'}}
                  alt="about us"
                  className="img-fluid"
                />
                <p>On February 22nd SWAG had its public debut at an online event co-organised by    
                  <a style={{color:'#f45652'}}href='https://www.linkedin.com/company/adr-association/'> Adra - AI-Data-Robotics-Association</a>, 
                  <a style={{color:'#f45652'}}href='https://www.linkedin.com/company/aiondemand/'> AI-on-Demand Platform</a> and the <a style={{color:'#f45652'}}href='https://www.linkedin.com/company/european-commission/'>European Commission</a>. 
                  The webinar showcased the future of innovation in AI, Data & Robotics, featuring the latest EU-funded Horizon projects in the field. 
</p>       
              <p>SWAG was presented in the section "Pushing the limit of physical intelligence and performance (RIA)". Discover the presentation video below, 
                which gives a succinct overview of the objectives, technology and expected impact of the project. All the other projects 
                that featured in the webinar can be checked out in the recording on the event webpage <a style={{color:'#f45652'}}href='https://adra-e.eu/events/launch-event-showcasing-future-innovation-ai-data-and-roboticshttps://adra-e.eu/events/launch-event-showcasing-future-innovation-ai-data-and-robotics'>here</a>,
               and the agenda with the timing can be consulted <a style={{color:'#f45652'}}href='https://adra-e.eu/events/launch-event-showcasing-future-innovation-ai-data-and-robotics'>here</a>.</p>
 
               <iframe width="360" height="215" src="https://www.youtube.com/embed/vSBEBhHKIUY?si=hJJnbvHKv1nL8NRy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>             </div>
            </div>
           
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
