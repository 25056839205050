import React from "react";
import { Link } from "react-router-dom";
import solution from "../../img/challenge2.svg"
const Cta = () => {
  return (
    <>
      <section
        className="download-section pt-100 background-img2"
        id='challenges'
        style={{
          height:'auto',
          background:
            "url('assets/img/iStock-966248982.jpg') no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-12">
              <div className="download-content text-white pb-100">
                <h2 className="text-white">The challenges
                </h2>
                <p style={{color:'whitesmoke'}}>
                SWAG will revolutionize the field of human motion assistance by eliminating challenges that are currently blocking progress
</p>

              <ul  style={{color:'whitesmoke'}}>
                <li  style={{marginBottom:'10px'}}><div className="d-flex align-items-center">
                  <div>
                      <div style={{width:'44px'}}>
                      <img style={{width:'34px',top:'4px', position:'relative'}} src={solution}/>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0"><b style={{color:'whitesmoke'}}>User comfort and efficiency</b></p>
                    </div>
                  </div>
                <p style={{ color:'whitesmoke',marginTop:'4px',}}>Existing wearable robots use heavy and bulky devices to assist isolated joints. The increased weight of the robotic components leads to increased metabolic costs and reduces the device’s energetic efficiency. Another common issue is the activation of specific joints, overlooking the overall impact on the full-body biomechanics. Moreover, current wearable solutions struggle to conform to an individual’s physiology, and to adjust to each user's unique motion needs during various daily activities.</p></li>
                <li style={{marginBottom:'10px'}}><div className="d-flex align-items-center">
                  <div>
                      <div style={{width:'44px'}}>
                      <img style={{width:'34px',top:'4px', position:'relative'}} src={solution}></img>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0"><b style={{color:'whitesmoke'}}>Pneumatic actuation</b></p>
                    </div>
                  </div> <p style={{marginTop:'4px', color:'whitesmoke'}}>Pneumatically powered exosuits offer a solution to the weight problem, allowing the use of inflatable, high-strength fabrics that can easily transform to stiff supporting structures via internal pressurization. However, pneumatic systems generally struggle with their power supply, as suitable portable pneumatic sources are not available, and only traditional air compressors have been used in past solutions, which are not practical, for various reasons.</p></li>
                <li style={{marginBottom:'10px'}}><div className="d-flex align-items-center">
                  <div>
                      <div style={{width:'44px'}}>
                      <img style={{width:'34px'}} src={solution}></img>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0"><b style={{color:'whitesmoke'}}>Data acquisition and processing</b></p>
                    </div>
                  </div> <p style={{marginTop:'4px', color:'whitesmoke'}}>Soft sensors have been developed to address the needs of soft systems, tracking pressure, strain, force and contact interactions. Soft sensing technologies and computational algorithms have advanced in collecting data from various sources in controlled settings and specific activities. However, these soft approaches face challenges in consistently functioning in real-world situations, across different applications and securely responding to unexpected inputs and interactions.</p></li>
              </ul>
              </div>
            </div>
           
          </div>
        </div>
      </section>
    </>
  );
};

export default Cta;

/*   <section
        className="download-section pt-100 background-img2"
        style={{
          height:'auto',
          background:
            "url('assets/img/iStock-966248982.jpg') no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-12">
              <div className="download-content text-white pb-100">
                <h2 className="text-white">SWAG solutions 
                </h2>
                <p style={{color:'whitesmoke'}}>
                In order to address these challenges and transform soft wearable robotics, SWAG will make advances in fabric-based soft robotics, integrated sensing, user intent tracking, and distributed actuation. Our goals include:
                </p>

              <ul  style={{color:'whitesmoke'}}>
                <li  style={{marginBottom:'10px'}}><img id='lamp' style={{width:'40px',float:'left',marginRight:'10px',position:'relative',top:'4px'}}src={solution}/>Introducing a new design approach for wearable robots based on adaptability and human-centric design. This involves using optimized inflatable fabrics to cover and support entire body regions, not just individual joints.</li>
                <li style={{marginBottom:'10px'}}><img id='lamp' style={{width:'40px',float:'left',marginRight:'10px',position:'relative',top:'4px'}}src={solution}/>Providing personalized real-time motion assistance using a combination of distributed sensing, control, and user-intent prediction algorithms based on data-driven models. This ensures accurate and adaptive support for various movements.</li>
                <li style={{marginBottom:'10px'}}><img id='lamp' style={{width:'40px',float:'left',marginRight:'10px',position:'relative',top:'4px'}}src={solution}/>Eliminating the need for tethered operation and simultaneously supporting muscles and joints throughout the entire lower limb system.  This is made possible by a single pneumatic source and a closed pneumatic circuit efficiently recycling air.</li>
                <li style={{marginBottom:'10px'}}><img id='lamp' style={{width:'40px',float:'left',marginRight:'10px',position:'relative',top:'4px'}}src={solution}/>Overcoming the limitations of wearable devices by adopting a multidisciplinary approach that combines soft design, embedded sensing, functional apparel, smart textile design, biomechanical modeling, materials science, distributed control; with AI at the centre.</li>
              </ul>
              </div>
            </div>
           
          </div>
        </div>
      </section>
      
      */