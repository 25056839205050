import React from "react";

const Promo = () => {
  return (
    <>
      <section id='nutshell' className="promo-section ptb-100">
       
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12" style={{textAlign:'justify'}}>
              <div className="section-heading text-center mb-10">
                <h2  style={{textAlign:'left'}}>In a nutshell</h2>
                <div className="row align-items-center">
                <div className="col-md-8">
              <div className="about-content-right">
            
                  <p >
                The SWAG project is a multidisciplinary initiative in the field of soft robotics, focusing on the development of lower limb exosuits: soft wearable exoskeletal robots to empower the lower body and core. 
                </p>
                <p >The project aims to replace traditional rigid materials found in exoskeletons, with high-strength, inflatable fabrics and sensing films to create smart, human-assistive, soft and lightweight garments. The envisioned exosuits will ensure user comfort and safety, while remaining virtually undetected, invisible under clothing due to their garment-like design.  </p>
              <p >SWAG exosuits will empower humans in activities from occupational assistance to daily mobility, fitness and immersive entertainment.</p>
              <p  >SWAG involves 13 partners from six EU countries and the UK, including leading academics, Research and Technology Organisations and two commercial partners.</p>
              </div>
            </div>
            
            <div className="col-lg-4" style={{marginTop:'-10px'}}>
            <img style={{borderRadius:'10px'}}
                  src="assets/img/shutterstock1.jpg"
                  width="320"
                  alt="about us"
                  className="img-fluid"
                />
                
                <img style={{marginTop:'10px',borderRadius:'10px'}}
                  src="assets/img/shutterstock3.jpg"
                  width="320"
                  alt="about us"
                  className="img-fluid"
                />
              </div></div>
            </div>
            </div> </div>
         
        </div>
      </section>
    </>
  );
};

export default Promo;
